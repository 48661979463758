<template>
    <div class="summary-card" :class="cardExtraClass">
        <div class="summary-card-content">
            <div class="container" :class="{'to-right':toRight}">
                <div class="summary-card-body">
                    <slot name="summary-card-content"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SummaryCard',
    components: {},
    props: ['cardExtraClass', 'toRight']
};
</script>
