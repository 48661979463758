<template>
    <div>
        <MainHeader visible-entry="visibleEntry" />
        <MainBanner services-banner="true">
            <template v-slot:banner-overlay>
                <div class="services-banner-overlay clearbliss"></div>
            </template>
            <template v-slot:banner-hashtag>
                <img src="../../assets/images/clearbliss-logo-graphics.png" alt="image" style="max-width: 20px" />
                ClearBliss
            </template>
            <template v-slot:main-banner-image>
                <div
                    class="main-banner-image text-center d-flex align-items-center justify-content-center"
                    style="top: 0; height: 100%"
                >
                    <img
                        src="../../assets/images/features-logo-clearbliss-hover.png"
                        style="max-height: 308px"
                        alt="image"
                        class="features-dark-logo"
                    />
                    <img
                        src="../../assets/images/features-logo-clearbliss-hover.png"
                        style="max-height: 208px"
                        alt="image"
                        class="features-dark-logo"
                    />
                </div>
            </template>
            <template v-slot:banner-title>
                Stress less and <br />
                find more joy, <br />
                all from one app.
            </template>
            <template v-slot:banner-subtitle>
                &nbsp;
            </template>
        </MainBanner>
        <ProfessionalExperiences class="pt-0" style="margin-top: 100px; margin-bottom: 200px">
            <template v-slot:main-content>
                <div class="row align-items-center">
                    <div class="col-lg-6 g-0 with-half-background">
                        <div class="about-image about-image-p2">
                            <img
                                src="../../assets/images/phone-Website_meditation-3.png"
                                alt="image"
                                style="max-height: 600px; transform: scale(1.1)"
                            />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-content p-0">
                            <div class="about-item p-0">
                                <div class="about-content p-0">
                                    <h3>
                                        Guided <br />
                                        Meditations
                                    </h3>
                                    <p class="mb-3">
                                        Enjoy topical meditations that help you tackle any personal challenges you may
                                        be facing.
                                    </p>
                                    <p>Cultivate awareness of thoughts and emotions. Find inner peace. Live happier.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ProfessionalExperiences>
        <ProfessionalExperiences
            class="mb-3 no-before no-after"
            style="margin-top: 100px; padding: 150px 0"
            service-name="clearbliss"
        >
            <template v-slot:main-content>
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="about-content p-0">
                            <div class="about-item p-0 d-flex justify-content-center">
                                <div class="about-content p-0">
                                    <h3 class="text-white">
                                        Connect with <br />
                                        your inner self
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 g-0 with-half-background">
                        <div class="about-content p-0">
                            <div class="about-item p-0 d-flex justify-content-center">
                                <div class="about-content p-0">
                                    <p class="mb-3 text-white" style="max-width: 350px">
                                        Explore the depths of your being, observing your thoughts, emotions, and
                                        sensations with curiosity and detachment.
                                    </p>
                                    <p class="text-white" style="max-width: 350px">
                                        Learn to be fully present in each moment and to connect with the stillness
                                        within you. With Clear Bliss, you can cultivate a greater sense of
                                        self-awareness, inner peace, and clarity.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ProfessionalExperiences>
        <h2 class="standalone-title mt-5 mb-5 text-center fw-bold">Breathe in the peace</h2>
        <ServicesCaseStudy service-name="clearbliss" video-name="forest">
            <template v-slot:service-case-study-content>
                <SummaryCard to-right="true">
                    <template v-slot:summary-card-content>
                        <h3 class="title">Relieve Anxiety</h3>
                        <p>
                            Meditation has been known to relieve symptoms of anxiety, as well as improve sleep and
                            overall well-being.
                         </p>
                    </template>
                </SummaryCard>
            </template>
        </ServicesCaseStudy>
        <MainFooter />
    </div>
</template>

<script>
import MainHeader from '@/components/Layout/MainHeader';
import MainBanner from '@/components/HomeTwo/MainBanner';
import ProfessionalExperiences from '@/components/HomeTwo/ProfessionalExperiences';
import ServicesCaseStudy from '@/components/ServicesCaseStudy/ServicesCaseStudy';
import SummaryCard from '@/components/ServicesCaseStudy/SummaryCard';
import MainFooter from '@/components/Layout/MainFooter';
export default {
    name: 'ClearBlissServicePage',
    components: {
        MainHeader,
        MainBanner,
        ProfessionalExperiences,
        ServicesCaseStudy,
        SummaryCard,
        MainFooter,
    },
};
</script>
