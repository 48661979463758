import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import AOS from 'aos';
import VueVectorMap from 'vuevectormap';
import VueObserveVisibility from 'vue-observe-visibility';

import 'aos/dist/aos.css';
import 'vuevectormap/src/scss/vuevectormap.scss';
import './assets/custom.scss';
require('jsvectormap/dist/maps/world');

const app = createApp(App).use(router);
app.use(AOS.init());
app.use(VueObserveVisibility);
app.use(VueVectorMap, {
    markers: [
        { name: 'Spain', coords: [40.4168, -2] },
        { name: 'Malaysia', coords: [3.1319, 101.6841] },
        {
            name: 'United States',
            coords: [37.0902, -95.7129],
        },
        {
            name: 'Austria',
            coords: [48.2082, 16.3738],
        },
        {
            name: 'Bahrain',
            coords: [26.0667, 50.5577],
        },
        {
            name: 'Belgium',
            coords: [50.5039, 4.4699],
        },
        {
            name: 'Bulgaria',
            coords: [42.7339, 25.4858],
        },
        {
            name: 'Cameroon',
            coords: [7.3697, 12.3547],
        },
        {
            name: 'Czech Republic',
            coords: [49.8175, 15.473],
        },
        {
            name: 'Egypt',
            coords: [26.8206, 30.8025],
        },
        {
            name: 'France',
            coords: [46.2276, 2.2137],
        },
        {
            name: 'Germany',
            coords: [51.1657, 10.4515],
        },
        {
            name: 'Greece',
            coords: [39.0742, 21.8243],
        },
        {
            name: 'Indonesia',
            coords: [-0.7893, 113.9213],
        },
        {
            name: 'Iraq',
            coords: [33.2232, 43.6793],
        },
        {
            name: 'Italy',
            coords: [41.8719, 12.5674],
        },
        {
            name: 'Ivory Coast',
            coords: [7.54, -5.5471],
        },
        {
            name: 'Jordan',
            coords: [30.5852, 36.2384],
        },
        {
            name: 'Kazakhstan',
            coords: [48.0196, 66.9237],
        },
        {
            name: 'Kuwait',
            coords: [29.3117, 47.4818],
        },
        {
            name: 'Mexico',
            coords: [23.6345, -102.5528],
        },
        {
            name: 'Morocco',
            coords: [31.7917, -7.0926],
        },
        {
            name: 'Myanmar',
            coords: [21.9162, 95.956],
        },
        {
            name: 'Netherlands',
            coords: [52.1326, 5.2913],
        },
        {
            name: 'Nigeria',
            coords: [9.082, 8.6753],
        },
        {
            name: 'Oman',
            coords: [21.4735, 55.9754],
        },
        {
            name: 'Pakistan',
            coords: [30.3753, 69.3451],
        },
        {
            name: 'Poland',
            coords: [51.9194, 19.1451],
        },
        {
            name: 'Portugal',
            coords: [39.3999, -8.2245],
        },
        {
            name: 'Saudi Arabia',
            coords: [23.8859, 45.0792],
        },
        {
            name: 'Senegal',
            coords: [14.4974, -14.4524],
        },
        {
            name: 'Serbia',
            coords: [44.0165, 21.0059],
        },
        {
            name: 'Slovakia',
            coords: [48.669, 19.699],
        },
        {
            name: 'South Africa',
            coords: [-30.5595, 22.9375],
        },
        {
            name: 'Switzerland',
            coords: [46.8182, 8.2275],
        },
        {
            name: 'Tunisia',
            coords: [33.8869, 9.5375],
        },
        {
            name: 'United Arab Emirates',
            coords: [23.4241, 53.8478],
        },
    ],
    labels: {},
    markerStyle: {
        initial: {
            stroke: '#ffffff',
            strokeWidth: 1,
            fill: '#eb5e20',
            fillOpacity: 1,
            strokeOpacity: 1,
            r: 5,
        },
    },
    zoomOnScroll: false,
    selectedRegions: [
        'ES',
        'US',
        'MY',
        'AT',
        'BH',
        'BE',
        'BG',
        'CM',
        'CZ',
        'EG',
        'FR',
        'DE',
        'GR',
        'ID',
        'IQ',
        'IT',
        'CI',
        'JO',
        'KZ',
        'KW',
        'MX',
        'MA',
        'MM',
        'NL',
        'NG',
        'OM',
        'PK',
        'PL',
        'PT',
        'SA',
        'SN',
        'RS',
        'SK',
        'ZA',
        'CH',
        'TN',
        'AE',
    ],
    regionStyle: {
        initial: {
            fill: '#D5D6D6',
            stroke: '#fff',
            strokeWidth: 0.1,
            fillOpacity: 1,
        },
        selected: { fill: '#3b01fd', fillOpacity: 1 },
        hover: {
            fill: '#eb5e20',
            fillOpacity: 1,
        },
    },
});
app.mount('#app');
