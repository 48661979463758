<template>
    <section
        id="contact"
        class="contact-area pb-100 pt-5 jarallax"
        ref="jarallax"
        data-jarallax-video="m4v:../../assets/video/01.m4v,webm:.../../assets/video/01.webm,ogv:./video/01.ogv"
    >
        <div class="container">
            <div class="section-title">
                <h2 class="text-white">Contact</h2>
            </div>
            <div class="contact-form bg-white pt-4 pb-4 rounded-3 p-5" style="max-width: 600px">
                <h3 class="mb-2 text-start">Get in Touch</h3>
                <div class="alert alert-info text-center" v-if="successfullySubmitted">
                    <strong>Thank you!</strong> Your message has been sent.
                </div>
                <form
                    id="contactForm"
                    @submit.prevent="submit"
                >
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    class="form-control"
                                    placeholder="Name*"
                                    v-model="contactForm.name"
                                    :class="{'input-error': formErrors.find(fe => Object.keys(fe)[0] === 'name')}"
                                />
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    class="form-control"
                                    placeholder="Email*"
                                    v-model="contactForm.email"
                                    :class="{'input-error': formErrors.find(fe => Object.keys(fe)[0] === 'email')}"
                                />
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="phone_number"
                                    id="phone_number"
                                    class="form-control"
                                    placeholder="Phone"
                                    v-model="contactForm.phone"
                                />
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea
                                    name="message"
                                    class="form-control"
                                    id="message"
                                    cols="30"
                                    rows="5"
                                    placeholder="Message"
                                    v-model="contactForm.message"
                                ></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button type="button" class="default-btn" @click="submit" :disabled="formSubmitting">
                                Send Message <i class="ri-arrow-right-line"></i><span></span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import { jarallax, jarallaxVideo } from 'jarallax';
import axios from 'axios';
export default {
    name: 'ContactUs',
    data() {
        return {
            formErrors: [],
            contactForm: {
                name: '',
                email: '',
                phone: '',
                message: '',
            },
            formSubmitting: false,
            successfullySubmitted: false,
            serverError: ''
        };
    },
    mounted() {
        jarallaxVideo();
        jarallax(this.$refs.jarallax, {
            speed: 0.2,
            videoSrc: 'm4v:../../video/01.m4v,webm:../../video/01.webm,ogv:../../video/01.ogv',
        });
        // jarallax;
    },
    methods: {
        submit(e) {
            if (!this.isFormValid()) {
                return;
            }
            e.preventDefault();
            this.formSubmitting = true;
            this.formErrors = [];
            axios.post('https://api-node-dcb.theglory.io/api/v1/utilities/send-ddg-contact-email', {
                name: this.contactForm.name,
                email: this.contactForm.email,
                phone: this.contactForm.phone,
                message: this.contactForm.message
            }).then(() => {
                this.successfullySubmitted = true;
                this.contactForm = {
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                };
                setTimeout(() => {
                   this.successfullySubmitted = false;
                }, 2000);
            }).catch((error) => {
                this.serverError = error.response?.data?.message || 'Something went wrong. Please try again later.';
            }).finally(() => {
                this.formSubmitting = false;
            });
        },
        isFormValid() {
            if (this.contactForm.name && this.contactForm.email && this.isValidEmail()) {
                return true;
            }
            this.formErrors = [];
            if (!this.contactForm.email || (this.contactForm.email && !this.isValidEmail())) {
                this.formErrors.push({email: 'Please provide a valid email.'});
            }
            if (!this.contactForm.name) {
                this.formErrors.push({name: 'Please provide a name.'});
            }
            return false;
        },
        isValidEmail() {
            if (!this.contactForm.email) {
                return false;
            }
            return this.contactForm.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
    },
};
</script>
