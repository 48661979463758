<template>
    <div>
        <div :class="['navbar-area', { 'is-sticky': isSticky }]">
            <div class="main-navbar">
                <div class="container">
                    <nav class="navbar navbar-expand-xl navbar-light">
                        <router-link
                            class="navbar-brand"
                            :to="{ path: '/home', hash: '#introduction', query: { lang: language } }"
                        >
                            <img
                                src="../../assets/images/ddg-black-texct.png"
                                class="black-logo"
                                alt="logo"
                                style="max-height: 75px"
                            />
                            <!-- <img src="../../assets/images/white-logo.png" class="white-logo" alt="logo"> -->
                        </router-link>

                        <div
                            class="navbar-toggler"
                            @click="active = !active"
                            :aria-pressed="active ? 'true' : 'false'"
                            v-bind:class="{ active: button_active_state }"
                            v-on:click="button_active_state = !button_active_state"
                        >
                            <i class="ri-menu-line"></i>
                            <i class="ri-close-line"></i>
                        </div>

                        <div class="collapse navbar-collapse" :class="{ show: active }">
                            <ul class="navbar-nav ms-5">
                                <li class="nav-item">
                                    <router-link
                                        section="#introduction"
                                        class="nav-link"
                                        :to="{ path: '/home', hash: '#introduction', query: { lang: language } }"
                                        exact
                                        >Home</router-link
                                    >
                                </li>

                                <li class="nav-item">
                                    <router-link
                                        section="#about"
                                        class="nav-link"
                                        :to="{ path: '/home', hash: '#about', query: { lang: language } }"
                                        >About</router-link
                                    >
                                </li>

                                <li class="nav-item">
                                    <router-link
                                        :to="{ path: '/home', hash: '#services', query: { lang: language } }"
                                        section="#services"
                                        class="nav-link"
                                        >Services
                                        <i class="ri-add-line"></i>
                                    </router-link>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <router-link
                                                :to="{ path: '/services/play-games', query: { lang: language } }"
                                                class="nav-link"
                                            >
                                                PlayGames
                                            </router-link>
                                        </li>

                                        <li class="nav-item">
                                            <router-link
                                                :to="{ path: '/services/the-halo', query: { lang: language } }"
                                                class="nav-link"
                                            >
                                                TheHalo
                                            </router-link>
                                        </li>

                                        <li class="nav-item">
                                            <router-link
                                                :to="{ path: '/services/clearbliss', query: { lang: language } }"
                                                class="nav-link"
                                            >
                                                ClearBliss
                                            </router-link>
                                        </li>

                                        <li class="nav-item">
                                            <router-link
                                                :to="{ path: '/services/sleepcloud', query: { lang: language } }"
                                                class="nav-link"
                                            >
                                                SleepCloud
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <router-link
                                        :to="{ path: '/home', hash: '#contact', query: { lang: language } }"
                                        section="#contact"
                                        class="nav-link"
                                        >Contact</router-link
                                    >
                                </li>

                                <li class="nav-item" v-if="language">
                                    <router-link :to="{ path: '/de/impressum', query: { lang: language } }" class="nav-link"
                                        >Imprint</router-link
                                    >
                                </li>
                            </ul>
                        </div>

                        <div class="others-options d-flex align-items-center">
                            <div class="option-item">
                                <router-link
                                    :to="{ path: '/home', hash: '#contact', query: { lang: language } }"
                                    href="#contact"
                                    class="default-btn"
                                >
                                    Let’s Talk
                                    <i class="ri-message-line"></i>
                                    <span></span>
                                </router-link>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainNavbar',
    props: ['lang'],
    data() {
        return {
            isSticky: false,
            active: false,
            button_active_state: false,
            search: false,
            button_search_state: false,
            isSideMenu: false,
            language: this.lang || this.$route.query?.lang,
        };
    },
    mounted() {
        const that = this;
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY;
            if (scrollPos >= 100) {
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        });
    },
    methods: {
        isSideMenuMethod(isSideMenu) {
            return (this.isSideMenu = !isSideMenu);
        },
    },
};
</script>
