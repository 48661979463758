<template>
    <div>
        <MainHeader visible-entry="visibleEntry" :lang="lang" />
        <div
            v-observe-visibility="{
                callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'introduction'),
                intersection: {
                    threshold: 0.5,
                },
            }"
        >
            <MainBanner with-shapes="true"></MainBanner>
        </div>
        <div
            v-observe-visibility="{
                callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'about'),
                intersection: {
                    threshold: 1,
                },
            }"
        >
            <AdvancedAnalytics />
        </div>
        <div
            v-observe-visibility="{
                callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'about'),
                intersection: {
                    threshold: 1,
                },
            }"
        >
            <ProfessionalExperiences />
        </div>
        <div
            v-observe-visibility="{
                callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'services'),
                intersection: {
                    threshold: 1,
                },
            }"
        >
            <OurFeatures />
        </div>
        <div
            v-observe-visibility="{
                callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'services'),
                intersection: {
                    threshold: 1,
                },
            }"
        >
            <TechSupport />
        </div>
        <div
            v-observe-visibility="{
                callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'services'),
                intersection: {
                    threshold: 1,
                },
            }"
        >
            <WorldMap />
        </div>
        <!--        <div-->
        <!--            v-observe-visibility="{-->
        <!--                callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'services'),-->
        <!--                intersection: {-->
        <!--                    threshold: 1,-->
        <!--                },-->
        <!--            }"-->
        <!--        >-->
        <!--            <PartnerLogo />-->
        <!--        </div>-->
        <div
            v-observe-visibility="{
                callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'contact'),
                intersection: {
                    threshold: 1,
                },
            }"
        >
            <ContactUs />
        </div>
        <MainFooter :lang="lang" />
    </div>
</template>

<script>
import MainHeader from '../Layout/MainHeader';
import MainBanner from '../HomeTwo/MainBanner';
import ProfessionalExperiences from '../HomeTwo/ProfessionalExperiences';
import OurFeatures from '../FeaturesTwo/OurFeatures';
// import PartnerLogo from '../Common/PartnerLogo';
import MainFooter from '../Layout/MainFooter';
import TechSupport from '@/components/Common/TechSupport';
import WorldMap from '@/components/WorldMap/WorldMap';
import ContactUs from '@/components/Contact/ContactUs';
import AdvancedAnalytics from "@/components/HomeTwo/AdvancedAnalytics.vue";

export default {
    name: 'HomePageTwo',
    components: {
        AdvancedAnalytics,
        ContactUs,
        MainHeader,
        MainBanner,
        ProfessionalExperiences,
        OurFeatures,
        TechSupport,
        WorldMap,
        MainFooter,
    },
    data() {
        return {
            visibleEntry: [],
        };
    },
    props: ['lang'],
    methods: {
        visibilityChanged(isVisible, entry, section) {
            if (isVisible) {
                this.visibleEntry.push(entry);
                if (!this.$el.querySelectorAll(`.main-navbar .navbar-nav a`).length) {
                    return;
                }
                if (!this.$el.querySelector(`.main-navbar .navbar-nav a[section="#${section}"]`)) {
                    return;
                }
                if (section) {
                    this.$el.querySelectorAll(`.main-navbar .navbar-nav a`).forEach((item) => {
                        item.parentElement.classList.remove('active');
                    });
                    this.$el
                        .querySelector(`.main-navbar .navbar-nav a[section="#${section}"]`)
                        .parentElement.classList.add('active');
                }
                return;
            }
            this.visibleEntry = [];
        },
    },
};
</script>
