<template>
    <MainHeader visible-entry="visibleEntry" :lang="lang" />
    <MainBanner with-shapes="true" banner-class="small-banner">
        <template v-slot:banner-overlay>
            <div class="services-banner-overlay play-games"></div>
        </template>
        <template v-slot:banner-hashtag>
            <img src="../../assets/images/DDG-fav-icon-blue-orangee.png" alt="image" style="max-width: 20px" />
            Impressum
        </template>
        <template v-slot:main-banner-image>
            <div class="main-banner-image top-0 text-center mt-0"></div>
        </template>
        <template v-slot:banner-title> &nbsp;</template>
        <template v-slot:banner-subtitle> &nbsp; </template>
    </MainBanner>
    <div class="container">
        <!--        <h2 class="text-center mt-5 mb-5">Muster Impressum</h2>-->
        <!--        <p class="fw-bold">-->
        <!--            Rechtlicher Hinweis: Das nachfolgende Muster ist nur als Formulierungshilfe zu verstehen und bietet erste-->
        <!--            Anregungen, wie ein möglicher Inhalt eines „Impressum“ aussehen könnte. Vor einer Nutzung und einfacher-->
        <!--            Übernahme des Textes muss der Verwender eigenverantwortlich und selbständig prüfen, ob der Text des Musters-->
        <!--            den gesetzlichen Anforderungen genügt und mit der aktuellen Rechtsprechung vereinbar ist. Ferner sollte-->
        <!--            geprüft werden, ob das Muster unter Umständen an die konkrete Verwendung bzw. die zu regelnde Situation-->
        <!--            und/oder die Rechtsentwicklung angepasst werden muss.-->
        <!--            <br />-->
        <!--            Das Muster erhebt keinen Anspruch auf Vollständigkeit, Richtigkeit und Aktualität.-->
        <!--        </p>-->
        <!--        <p>Das folgende Muster „Impressum“ können Sie kostenlos für Ihre Homepage übernehmen.</p>-->
        <!--        <p>-->
        <!--            Es besteht in der Regel für jede Homepage eine Impressumspflicht, außer sie dient ausschließlich-->
        <!--            persönlichen oder familiären Zwecken. Die Pflicht zum Führen eines Impressums geht auf § 5 des-->
        <!--            Telemediengesetzes (TMG) zurück. Bitte beachten Sie, dass Sie in Abhängigkeit einer besonderen Rechtsform-->
        <!--            (z. B. GbR, OHG, KG, GmbH) Zusatzangaben machen müssen, die hier nicht aufgeführt sind (wie z. B.-->
        <!--            Registergericht: AG München, HRB-Nummer: 98765, Umsatzsteueridentifikationsnummer: 12345678, Nennung eines-->
        <!--            vertretungsberechtigten Geschäftsführers der GmbH). Daneben müssen gem. § 5 TMG, soweit vorhanden, die-->
        <!--            zuständige Aufsichtsbehörde und Kammer, die gesetzliche Berufsbezeichnung sowie die berufsrechtlichen-->
        <!--            Regelungen bezeichnet werden. Einen guten Leitfaden für die Herstellung eines Impressums finden Sie auch auf-->
        <!--            der Homepage des-->
        <!--            <a-->
        <!--                href="http://www.bmj.de/DE/Service/StatistikenFachinformationenPublikationen/Fachinformationen/LeitfadenzurImpressumspflicht/_node.html"-->
        <!--                target="_blank"-->
        <!--                >BMJ</a-->
        <!--            >.-->
        <!--        </p>-->
        <div style="background: #f4f8fc" class="p-5">
            <h4>Impressum</h4>
            <br />
            <div>Anbieter:</div>
            <p>
                GM Europe Ltd. <br />
                Dragonara Business Center 5th Floor, <br />
                Dragonara Road. St Julian’s STJ <br />
                3141 MALTA <br />
                MOSS Number MT25526923
            </p>
            <div>Kontakt:</div>
            <p>
                Telefon: <a href="tel:08001810871">0800 1810871</a>
                <br />
                E-Mail:
                <a href="mailto:contact@digitaldistribution.io">contact@digitaldistribution.io</a>
                <br />
                Website:
                <a href="https://digitaldistribution.io/de">https://digitaldistribution.io/de</a>
            </p>
            <br />
            <p>Bei redaktionellen Inhalten:</p>
            <p>
                Verantwortlich nach § 55 Abs.2 RStV <br />
                GM Europe Limited <br />
                Dragonara Business Center 5th Floor, <br />
                Dragonara Road. St Julian’s <br />
                STJ 3141 MALTA <br />
                EU VAT Number MT25526923<br />
                Anmeldung: C87607 <br />
                Kontakt: <br />
                Daniel Galea <br />
                Telefon: <a href="tel:08001810871">0800 1810871</a><br />
                E-Mail: <a href="mailto:daniel.galea@digitaldistribution.io">daniel.galea@digitaldistribution.io</a>
                <br />
            </p>
            <p>
                Wenn Sie einen Online-Streit anmelden möchten, besuchen Sie bitte:
                <strong
                    ><a
                        href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
                        target="_blank"
                        >https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage</a
                    ></strong
                >
            </p>
        </div>
    </div>
    <MainFooter :lang="lang" />
</template>

<script>
import MainHeader from '@/components/Layout/MainHeader.vue';
import MainBanner from '@/components/HomeTwo/MainBanner.vue';
import MainFooter from '@/components/Layout/MainFooter.vue';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Impressum',
    components: { MainFooter, MainBanner, MainHeader },
    props: ['lang'],
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
};
</script>
