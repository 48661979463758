<template>
    <div>
        <MainHeader visible-entry="visibleEntry" />
        <MainBanner services-banner="true">
            <template v-slot:banner-overlay>
                <div class="services-banner-overlay sleepcloud"></div>
            </template>
            <template v-slot:banner-hashtag>
                <img src="../../assets/images/sleepcloud-logo-graphics.png" alt="image" style="max-width: 20px" />
                SleepCloud
            </template>
            <template v-slot:main-banner-image>
                <div
                    class="main-banner-image text-center d-flex align-items-center justify-content-center"
                    style="top: 0; height: 100%"
                >
                    <img
                        src="../../assets/images/features-logo-sleepcloud-hover.png"
                        style="max-height: 308px"
                        alt="image"
                        class="features-dark-logo"
                    />
                    <img
                        src="../../assets/images/features-logo-sleepcloud-hover.png"
                        style="max-height: 208px"
                        alt="image"
                        class="features-dark-logo"
                    />
                </div>
            </template>
            <template v-slot:banner-title> Choose your calm <br /> </template>
            <template v-slot:banner-subtitle>
                &nbsp;
            </template>
        </MainBanner>
        <ProfessionalExperiences class="pt-0" style="margin-top: 100px; margin-bottom: 200px">
            <template v-slot:main-content>
                <div class="row align-items-center">
                    <div class="col-lg-6 g-0 with-half-background">
                        <div class="about-image about-image-p2">
                            <img
                                src="../../assets/images/phone-Website_cloud1.png"
                                alt="image"
                                style="max-height: 600px; transform: scale(1.2)"
                            />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-content p-0">
                            <div class="about-item p-0">
                                <div class="about-content p-0 position-relative" style="z-index: 2">
                                    <h3>Choose your calm</h3>
                                    <p class="mb-3">Guided meditations offer a dose wellness before falling asleep.</p>
                                    <p>Sleep sounds provide calming narration over soothing sounds of nature.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ProfessionalExperiences>
        <ProfessionalExperiences
            class="mb-3 no-before no-after"
            style="margin-top: 100px; padding: 50px 0"
            service-name="sleepcloud"
        >
            <template v-slot:main-content>
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="about-content p-0">
                            <div class="about-item p-0">
                                <div class="about-content p-0">
                                    <h3 class="text-white">
                                        Fall asleep faster. <br />
                                        Stay asleep longer.
                                    </h3>
                                    <p class="mb-3 text-white" style="max-width: 350px">
                                        Nightly sleep audio that is custom designed to relax you for the rest you
                                        deserve.
                                    </p>
                                    <p class="mb-3 text-white" style="max-width: 350px">
                                        Wake up feeling rejuvenated and ready to tackle the day.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 g-0 with-half-background">
                        <div class="about-image">
                            <img
                                src="../../assets/images/phone-mock_sleepcloud.png"
                                alt="image"
                                style="max-height: 600px; transform: translate(-100px, 0) scale(1.2)"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </ProfessionalExperiences>
        <h2 class="standalone-title mt-5 mb-5 text-center fw-bold">Relax. Recharge. Replenish.</h2>
        <ServicesCaseStudy service-name="sleepcloud" video-name="pier">
            <template v-slot:service-case-study-content>
                <SummaryCard>
                    <template v-slot:summary-card-content>
                        <h3 class="title">The Best Sleep</h3>
                        <p>
                            Enjoy a variety of guided bedtime stories, meditations and sounds specially crafted to help
                            you drift off into a deep and restful sleep.
                        </p>
                    </template>
                </SummaryCard>
            </template>
        </ServicesCaseStudy>
        <MainFooter />
    </div>
</template>

<script>
import MainHeader from '@/components/Layout/MainHeader';
import MainBanner from '@/components/HomeTwo/MainBanner';
import ProfessionalExperiences from '@/components/HomeTwo/ProfessionalExperiences';
import ServicesCaseStudy from '@/components/ServicesCaseStudy/ServicesCaseStudy';
import SummaryCard from '@/components/ServicesCaseStudy/SummaryCard';
import MainFooter from '@/components/Layout/MainFooter';
export default {
    name: 'TheHaloServicePage',
    components: {
        MainHeader,
        MainBanner,
        ProfessionalExperiences,
        ServicesCaseStudy,
        SummaryCard,
        MainFooter,
    },
};
</script>
