<template>
    <section class="about-area pt-100" id="about" :class="serviceName">
        <div class="container">
            <slot name="main-content">
                <div class="row align-items-center">
                    <div class="col-lg-6 g-0">
                        <div class="about-image">
                            <img src="../../assets/images/main-banner-about-illustration.png" alt="image" />
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="about-item">
                            <div class="about-content">
                                <div class="tag">
                                    <img src="../../assets/images/DDG-fav-icon-blue-orangee.png" alt="image" />
                                </div>
                                <h3>Next-Level Services</h3>
                                <p class="mb-3">
                                    At Digital Distribution Group we aim to provide exceptional digital experiences for
                                    our customers. Recently we have diversified beyond our gaming services to include
                                    unique content that impacts the wellness of our users.
                                </p>
                                <p>
                                    We will continue to enhance people’s lives by expanding our catalog breadth,
                                    creating compelling content, and delivering superior results for our stakeholders.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
        <div class="about-shape-1">
            <img src="../../assets/images/about/shape-1.png" alt="image" />
        </div>
        <div class="about-shape-2">
            <img src="../../assets/images/about/shape-2.png" alt="image" />
        </div>
        <div class="about-shape-3">
            <img src="../../assets/images/about/shape-3.png" alt="image" />
        </div>
        <div class="about-shape-6">
            <img src="../../assets/images/about/shape-4.png" alt="image" />
        </div>
        <div class="about-shape-7">
            <img src="../../assets/images/about/shape-5.png" alt="image" />
        </div>
    </section>
</template>

<script>
export default {
    name: 'ProfessionalExperiences',
    props:['serviceName']
};
</script>
