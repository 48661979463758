<template>
    <div>
        <MainHeader visible-entry="visibleEntry" />
        <MainBanner services-banner="true">
            <template v-slot:banner-overlay>
                <div class="services-banner-overlay play-games"></div>
            </template>
            <template v-slot:banner-hashtag>
                <img src="../../assets/images/play-games-logo-graphics.png" alt="image" style="max-width: 20px" />
                PlayGames
            </template>
            <template v-slot:main-banner-image>
                <div class="main-banner-image top-0 text-center">
                    <img
                        src="../../assets/images/play-games-logo-hover.png"
                        style="max-height: 308px"
                        alt="image"
                        class="features-dark-logo"
                    />
                    <img
                        src="../../assets/images/play-games-logo-hover.png"
                        alt="image"
                        class="features-dark-logo d-none"
                    />
                </div>
            </template>
            <template v-slot:banner-title>
                1600+ games. <br />
                One subscription.
            </template>
            <template v-slot:banner-subtitle>
                Puzzle, Action, Adventure, Racing, Strategy and More, all through a single access point.
            </template>
        </MainBanner>
        <ProfessionalExperiences class="pt-0 mb-3" style="margin-top: 100px">
            <template v-slot:main-content>
                <div class="row align-items-center">
                    <div class="col-lg-6 g-0 with-half-background">
                        <div class="about-image about-image-p2">
                            <img
                                src="../../assets/images/Phone_sponge.png"
                                alt="image"
                                style="max-height: 600px; transform: scale(1.3)"
                            />
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="about-content p-0">
                            <div class="about-item p-0">
                                <div class="about-content p-0">
                                    <h3>
                                        No ads. <br />
                                        No in-app purchases.
                                    </h3>
                                    <p class="mb-3">
                                        We've taken the grief out of gaming. <br />
                                        All games come in their full version.
                                    </p>
                                    <p>
                                        Stream or download. <br />
                                        Have it your way. Play online or download available titles at your convenience.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ProfessionalExperiences>
        <GamesSlider />
        <h2 class="standalone-title mt-5 mb-5 text-center fw-bold">A Gaming Paradise</h2>
        <ServicesCaseStudy service-name="play-games" video-name="ps">
            <template v-slot:service-case-study-content>
                <SummaryCard>
                    <template v-slot:summary-card-content>
                        <h3 class="title">
                            2 Million <br />
                            Subscribers
                        </h3>
                        <p>Over 1600 Games Available</p>
                        <p>We are the fastest growing gaming service in the world.</p>
                    </template>
                </SummaryCard>
            </template>
        </ServicesCaseStudy>
        <MainFooter />
    </div>
</template>

<script>
import MainHeader from '@/components/Layout/MainHeader';
import MainBanner from '@/components/HomeTwo/MainBanner';
import ProfessionalExperiences from '@/components/HomeTwo/ProfessionalExperiences';
import GamesSlider from '@/components/GamesSlider/GamesSlider';
import MainFooter from '@/components/Layout/MainFooter';
import ServicesCaseStudy from '@/components/ServicesCaseStudy/ServicesCaseStudy';
import SummaryCard from '@/components/ServicesCaseStudy/SummaryCard';
export default {
    name: 'PlayGamesServicePage',
    components: {
        SummaryCard,
        ServicesCaseStudy,
        MainHeader,
        MainBanner,
        ProfessionalExperiences,
        GamesSlider,
        MainFooter,
    },
};
</script>
