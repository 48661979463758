import { createRouter, createWebHistory } from 'vue-router';

// All Pages
import HomePageTwo from '../components/Pages/HomePageTwo';
import ErrorPage from '../components/Pages/ErrorPage';
import PlayGamesServicePage from '@/components/Pages/PlayGamesServicePage';
import TheHaloServicePage from '@/components/Pages/TheHaloServicePage';
import SleepCloudServicePage from '@/components/Pages/SleepCloudServicePage';
import ClearBlissServicePage from '@/components/Pages/ClearBlissServicePage';
import ImpressumPage from '@/components/Pages/Impressum.page.vue';
import PrivacyPolicyPage from '@/components/Pages/PrivacyPolicyPage.vue';

const routes = [
    { path: '/', redirect: '/home' },
    { path: '/home', component: HomePageTwo },
    { path: '/services/play-games', component: PlayGamesServicePage },
    { path: '/services/the-halo', component: TheHaloServicePage },
    { path: '/services/sleepcloud', component: SleepCloudServicePage },
    { path: '/services/clearbliss', component: ClearBlissServicePage },
    { path: '/privacy-policy', component: PrivacyPolicyPage },
    { path: '/:pathMatch(.*)*', component: ErrorPage },
    { path: '/:lang', redirect: '/de/home' },
    { path: '/:lang/home', component: HomePageTwo, props: { lang: 'de' } },
    { path: '/:lang/impressum', component: ImpressumPage, props: { lang: 'de' } },
];

const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: '',
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            };
        }
        return { top: 0 };
    },
});

export default router;
