<template>
    <section class="features-area pt-100 pb-70" id="services">
        <div class="container">
            <div class="section-title">
                <h2>A World of Choices</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
                    <div class="single-features-box">
                        <router-link :to="{ path: '/services/play-games' }">
                            <img
                                src="../../assets/images/play-games-logo.png"
                                class="features-dark-logo"
                                alt="image"
                                style="height: 122px"
                            />
                            <img
                                src="../../assets/images/play-games-logo-hover.png"
                                class="features-light-logo"
                                alt="image"
                                style="height: 122px"
                            />
                        </router-link>
                        <h3>
                            <router-link :to="{ path: '/services/play-games' }">PlayGames</router-link>
                        </h3>
                        <p>
                            A variety of casual mobile games that Android and iOS customers can enjoy ad-free, fully
                            unlocked, without the need for in-app-purchases.
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
                    <div class="single-features-box">
                        <router-link :to="{ path: '/services/the-halo' }">
                            <img
                                src="../../assets/images/features-thehalo-logo.png"
                                style="max-height: 78px"
                                alt="image"
                                class="features-dark-logo"
                            />
                            <img
                                src="../../assets/images/features-thehalo-logo-hover.png"
                                style="max-height: 78px"
                                alt="image"
                                class="features-light-logo"
                            />
                        </router-link>
                        <h3>
                            <router-link :to="{ path: '/services/the-halo' }">Halo</router-link>
                        </h3>
                        <p>
                            Christian audio consisting of daily prayer, inspirational readings, and Bible stories, that
                            facilitate a deeper connection with God.
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
                    <div class="single-features-box">
                        <router-link :to="{ path: '/services/clearbliss' }">
                            <img
                                src="../../assets/images/features-logo-clearbliss.png"
                                alt="image"
                                style="max-height: 125px"
                                class="features-dark-logo"
                            />
                            <img
                                src="../../assets/images/features-logo-clearbliss-hover.png"
                                alt="image"
                                style="max-height: 125px"
                                class="features-light-logo"
                            />
                        </router-link>
                        <h3>
                            <router-link :to="{ path: '/services/clearbliss' }">ClearBliss</router-link>
                        </h3>
                        <p>
                            Mindfulness practices and guided meditations to help relieve stress, anxiety, and improve
                            overall well-being.
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
                    <div class="single-features-box">
                        <router-link :to="{ path: '/services/sleepcloud' }">
                            <img
                                src="../../assets/images/features-logo-sleepcloud.png"
                                style="max-height: 100px"
                                alt="image"
                                class="features-dark-logo"
                            />
                            <img
                                src="../../assets/images/features-logo-sleepcloud-hover.png"
                                style="max-height: 100px"
                                alt="image"
                                class="features-light-logo"
                            />
                        </router-link>
                        <h3>
                            <router-link :to="{ path: '/services/sleepcloud' }">SleepCloud</router-link>
                        </h3>
                        <p>
                            Unique Sleep Stories and Sounds that help listeners fall asleep faster and stay asleep
                            longer.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="features-shape-1">
            <img src="../../assets/images/features/shape-1.png" alt="image" />
        </div>
        <div class="features-shape-2">
            <img src="../../assets/images/features/shape-2.png" alt="image" />
        </div>
        <div class="features-shape-3">
            <img src="../../assets/images/features/shape-3.png" alt="image" />
        </div>
        <div class="features-shape-4">
            <img src="../../assets/images/features/shape-4.png" alt="image" />
        </div>
    </section>
</template>

<script>
export default {
    name: 'OurFeatures',
};
</script>
