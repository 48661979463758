<template>
    <footer>
        <section class="footer-info ptb-100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-lg-3">
                        <div class="iq-footer-box d-flex text-left">
                            <div class="iq-icon">
                                <i aria-hidden="true" class="ri-map-pin-line"></i>
                            </div>
                            <div class="footer-content">
                                <h4 class="iq-tw-6 iq-pb-10">Address</h4>
                                <p>Dragonara Business Center 5th Floor, Dragonara Road. St Julian’s STJ, 3141 Malta</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3">
                        <div class="iq-footer-box text-left d-flex flex-row">
                            <div class="iq-icon">
                                <i aria-hidden="true" class="ri-mail-line"></i>
                            </div>
                            <div class="footer-content">
                                <h4 class="iq-tw-6 iq-pb-10">Mail</h4>
                                <p>contact@digitaldistribution.io</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-sm-12 text-center">
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="me-3">
                                <router-link :to="{ path: '/de/impressum', query: { lang: language } }" v-if="language">
                                    <strong class="footer-link">Impressum</strong>
                                </router-link>
                            </div>
                            <div>
                                <router-link :to="{ path: '/privacy-policy' }">
                                    <strong class="footer-link">Privacy Policy</strong>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-sm-12 text-center">
                        <div class="footer-copyright mt-3 mb-3 pt-4">
                            Copyright @{{ currentYear }}
                            <a href="javascript:void(0)" class="text-green">Digital Distribution Group.</a> All Rights
                            Reserved
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Footer Info END -->
    </footer>
</template>

<script>
export default {
    name: 'MainFooter',
    props: ['lang'],
    data() {
        return {
            currentYear: new Date().getFullYear(),
            language: this.lang || this.$route.query?.lang,
        };
    },
};
</script>
