<template>
    <section class="advanced-analytics-area about-area pt-100" id="about" :class="serviceName">
        <div class="container">
            <slot name="main-content">
                <div class="row align-items-center">
                    <div class="col-lg-6 g-0 d-none d-md-block d-lg-none">
                        <div class="about-image">
                            <img src="../../assets/images/advanced-analytics.png" alt="image"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-item">
                            <div class="about-content">
                                <div class="tag position-relative z-1">
                                    <img src="../../assets/images/DDG-fav-icon-blue-orangee.png" alt="image" />
                                </div>
                                <h3>Advanced Analytics</h3>
                                <p class="mb-3">
                                    Digital Distribution Group excels in providing advanced tracking and analytical
                                    solutions in the competitive digital marketing landscape, ensuring complete
                                    transparency throughout your conversion funnel to optimize ROI.
                                </p>

                                <p class="mb-3">
                                    Our strategic integrations with top digital ad networks such as Google, Facebook,
                                    and TikTok, along with the world’s largest Demand Side Platforms, enable swift
                                    campaign launches and expansive reach.
                                </p>

                                <p class="mb-3">
                                    With a decade of experience, we offer unparalleled insights and continually adapt to
                                    the evolving digital ecosystem, consistently exceeding client expectations and
                                    setting industry benchmarks in strategic digital marketing execution.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 g-0 d-none d-lg-block">
                        <div class="about-image" style="margin-bottom: 40px; margin-left: -50px">
                            <img src="../../assets/images/advanced-analytics.png" alt="image" style="max-width: 110%;"/>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
        <div class="about-shape-3">
            <img src="../../assets/images/about/shape-3.png" alt="image" />
        </div>
    </section>
</template>

<script>
export default {
    name: 'AdvancedAnalytics',
    props: ['serviceName'],
};
</script>
