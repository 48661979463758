<template>
    <section :class="'main-banner-with-large-shape-area ' + $props.bannerClass" id="introduction">
        <slot name="banner-overlay"></slot>
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-12">
                    <div class="main-banner-white-content">
                        <div data-aos="fade-left" data-aos-duration="2000" class="tag">
                            <slot name="banner-hashtag">
                                <img
                                    src="../../assets/images/DDG-fav-icon-blue-orangee.png"
                                    alt="image"
                                    style="max-width: 20px"
                                />
                                #AgileProcess
                            </slot>
                        </div>
                        <h1 data-aos="fade-left" data-aos-duration="1000">
                            <slot name="banner-title"
                                >Trusted Mobile <br />
                                Experiences</slot
                            >
                        </h1>
                        <p data-aos="fade-left" data-aos-duration="1000" style="font-size: 21px">
                            <slot name="banner-subtitle">
                                We are a visionary group reinventing <br class="d-none d-md-block" />
                                the way people consume content on <br class="d-none d-md-block" />
                                their digital device.
                            </slot>
                        </p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <slot name="main-banner-image">
                        <div class="main-banner-image">
                            <img src="../../assets/images/BG.png" />
                            <img src="../../assets/images/Man.png" data-aos="fade-down" data-aos-duration="3000" />
                            <img src="../../assets/images/Phone1.png" data-aos="fade-down" data-aos-duration="2000" />
                            <img src="../../assets/images/Phone2.png" data-aos="fade-up" data-aos-duration="2000" />
                            <img src="../../assets/images/Lady.png" data-aos="fade-left" data-aos-duration="2000" />
                            <img src="../../assets/images/main-banner-illustration.png" />
                        </div>
                    </slot>
                </div>
            </div>
        </div>
        <div class="main-banner-shape-5" v-if="withShapes">
            <img src="../../assets/images/main-banner/banner-two/shape-1.png" alt="image" />
        </div>
        <div class="main-banner-shape-6" v-if="withShapes">
            <img src="../../assets/images/main-banner/banner-two/shape-2.png" alt="image" />
        </div>
        <div class="main-banner-shape-7" v-if="withShapes">
            <img src="../../assets/images/main-banner/banner-two/shape-3.png" alt="image" />
        </div>
        <div class="main-banner-shape-8" v-if="withShapes">
            <img src="../../assets/images/main-banner/banner-two/shape-4.png" alt="image" />
        </div>
        <div class="main-banner-large-shape">
            <img src="../../assets/images/main-banner/banner-two/large-shape.png" alt="image" />
        </div>
    </section>
</template>

<script>
export default {
    name: 'MainBanner',
    props: {
        withShapes: Boolean,
        servicesBanner: Boolean,
        bannerClass: String,
    },
};
</script>
