<template>
    <div>
        <MainHeader visible-entry="visibleEntry" />
        <MainBanner services-banner="true">
            <template v-slot:banner-overlay>
                <div class="services-banner-overlay the-halo"></div>
            </template>
            <template v-slot:banner-hashtag>
                <img src="../../assets/images/the-halo-logo-graphics.png" alt="image" style="max-width: 20px" />
                TheHalo
            </template>
            <template v-slot:main-banner-image>
                <div
                    class="main-banner-image text-center d-flex align-items-center justify-content-center"
                    style="top: 0; height: 100%"
                >
                    <img
                        src="../../assets/images/features-thehalo-logo-hover.png"
                        style="max-height: 308px"
                        alt="image"
                        class="features-dark-logo"
                    />
                    <img
                        src="../../assets/images/features-thehalo-logo-hover.png"
                        style="max-height: 208px"
                        alt="image"
                        class="features-dark-logo"
                    />
                </div>
            </template>
            <template v-slot:banner-title>
                #1 Destination <br />
                for Daily Prayer
            </template>
            <template v-slot:banner-subtitle> An all-new way to connect each day with Jesus Christ </template>
        </MainBanner>
        <ProfessionalExperiences class="pt-0" style="margin-top: 100px; margin-bottom: 200px">
            <template v-slot:main-content>
                <div class="row align-items-center">
                    <div class="col-lg-6 g-0 with-half-background">
                        <div class="about-image about-image-p2">
                            <img
                                src="../../assets/images/phone-Website_Halo-3.png"
                                alt="image"
                                style="max-height: 600px; transform: scale(1.1)"
                            />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-content p-0">
                            <div class="about-item p-0">
                                <div class="about-content p-0">
                                    <h3>
                                        Focus on what <br />
                                        matters most
                                    </h3>
                                    <p class="mb-3">
                                        Find spiritual support with our catalog of Christian Meditations <br />
                                        that will inspire you, motivate you, and teach you, while praising the Lord.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ProfessionalExperiences>
        <ProfessionalExperiences
            class="mb-3 no-before no-after"
            style="margin-top: 100px; padding: 50px 0"
            service-name="the-halo"
        >
            <template v-slot:main-content>
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="about-content p-0">
                            <div class="about-item p-0">
                                <div class="about-content p-0">
                                    <h3 class="text-white">
                                        A daily moment <br />
                                        of respite.
                                    </h3>
                                    <p class="mb-3 text-white" style="max-width: 350px">
                                        Enjoy new inspirational readings every day with our daily prayer, designed to
                                        connect you with God, help put your mind at ease, and release the tension of the
                                        day.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 g-0 with-half-background">
                        <div class="about-image">
                            <img
                                src="../../assets/images/phone-mock_halo2.png"
                                alt="image"
                                style="max-height: 600px; transform: translate(-100px, 0) scale(1.2)"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </ProfessionalExperiences>
        <h2 class="standalone-title mt-5 mb-5 text-center fw-bold">Reaffirm your Faith</h2>
        <ServicesCaseStudy service-name="the-halo" video-name="aflame">
            <template v-slot:service-case-study-content>
                <SummaryCard to-right="true">
                    <template v-slot:summary-card-content>
                        <h3 class="title">Heavenly Sleep</h3>
                        <p>
                            Fall asleep faster with exclusive sleep sounds that ease your body and mind as you drift
                            into deep sleep while focusing on the Word of God.
                        </p>
                    </template>
                </SummaryCard>
            </template>
        </ServicesCaseStudy>
        <MainFooter />
    </div>
</template>

<script>
import MainHeader from '@/components/Layout/MainHeader';
import MainBanner from '@/components/HomeTwo/MainBanner';
import ProfessionalExperiences from '@/components/HomeTwo/ProfessionalExperiences';
import ServicesCaseStudy from '@/components/ServicesCaseStudy/ServicesCaseStudy';
import SummaryCard from '@/components/ServicesCaseStudy/SummaryCard';
import MainFooter from '@/components/Layout/MainFooter';
export default {
    name: 'TheHaloServicePage',
    components: {
        MainHeader,
        MainBanner,
        ProfessionalExperiences,
        ServicesCaseStudy,
        SummaryCard,
        MainFooter,
    },
    mounted() {},
};
</script>
