<template>
    <section class="section game-sliders mt-md-5 pt-md-5 mt-sm-3" v-images-loaded:on="imageLoadedCallback()">
        <div class="game-rows-wrapper">
            <div class="slider-holder" data-direction="ltr" data-duration="100s">
                <div class="scrolling-slider game-tiles">
                    <div class="tile flip-box batman">
                        <div class="flip-box-inner">
                            <div class="flip-box-front"><img src="../../assets/images/games-tiles/batman.jpg" /></div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Batman Unlimited</h5>
                                        <p>
                                            Test your detective skills in Batman Unlimited: Gotham City’s Most Wanted!
                                            Race the Batmobile through the streets of Gotham. Chase Red Robin through
                                            the skies with the Bat-Glider.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box gumball">
                        <div class="flip-box-inner">
                            <div class="flip-box-front"><img src="../../assets/images/games-tiles/gumball.jpg" /></div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>The Amazing World of Gumball</h5>
                                        <p>The only way is up in this endless climbing game!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box megaman">
                        <div class="flip-box-inner">
                            <div class="flip-box-front"><img src="../../assets/images/games-tiles/megaman.jpg" /></div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Megaman</h5>
                                        <p>
                                            In the year 200X, six robots created by master roboticist Dr. Light are
                                            tampered with by the evil genius Dr. Wily, and go on a destructive rampage!
                                            Only one robot remains who can put a stop to Wily's plans and restore world
                                            peace: the heroic Mega Man.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box hitman-sniper">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/hitman-sniper.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Hitman Sniper</h5>
                                        <p>
                                            BECOME THE ULTIMATE SILENT ASSASSIN! Step into the shoes of Agent 47 in
                                            Hitman Sniper and discover the most compelling sniper experience on mobile.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box garfield-kart">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/garfield-kart.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Garfield Kart</h5>
                                        <p>
                                            What character do you want to play with? Garfield, Odie, Jon, Nermal... you
                                            choose! Take part in no-holds-barred crazy races in either multiplayer mode
                                            or against the computer!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box hendry-danger">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/hendry-danger.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Henry Danger: Crime Warp</h5>
                                        <p>
                                            The Game Shakers &amp; Henry Danger have joined forces to bring you the
                                            Henry Danger Crime Warp game! Collect mad coinage as you slide, jump, dive
                                            and punch giant popcorn monsters, in this non-stop dash to stop them!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box spongebob-frozen-face-off">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/spongebob-frozen-face-off.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Spongebob: Frozen Face-Off</h5>
                                        <p>
                                            Dive in to SpongeBob's first-ever interactive children's book app!
                                            Plankton's got quite the story to tell about SpongeBob and his friends on a
                                            Great Sleigh Race adventure.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box night-racer">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/night-racer.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Night Racer</h5>
                                        <p>
                                            Are you ready for the best race of your life? If yes, Night Racer is exactly
                                            what you need. Amazing realistic graphics will make your blood pump, while
                                            dangerous and life-taking stunts will make your palms sweat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="scrolling-slider game-tiles">
                    <div class="tile flip-box batman">
                        <div class="flip-box-inner">
                            <div class="flip-box-front"><img src="../../assets/images/games-tiles/batman.jpg" /></div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Batman Unlimited</h5>
                                        <p>
                                            Test your detective skills in Batman Unlimited: Gotham City’s Most Wanted!
                                            Race the Batmobile through the streets of Gotham. Chase Red Robin through
                                            the skies with the Bat-Glider.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box gumball">
                        <div class="flip-box-inner">
                            <div class="flip-box-front"><img src="../../assets/images/games-tiles/gumball.jpg" /></div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>The Amazing World of Gumball</h5>
                                        <p>The only way is up in this endless climbing game!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box megaman">
                        <div class="flip-box-inner">
                            <div class="flip-box-front"><img src="../../assets/images/games-tiles/megaman.jpg" /></div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Megaman</h5>
                                        <p>
                                            In the year 200X, six robots created by master roboticist Dr. Light are
                                            tampered with by the evil genius Dr. Wily, and go on a destructive rampage!
                                            Only one robot remains who can put a stop to Wily's plans and restore world
                                            peace: the heroic Mega Man.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box hitman-sniper">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/hitman-sniper.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Hitman Sniper</h5>
                                        <p>
                                            BECOME THE ULTIMATE SILENT ASSASSIN! Step into the shoes of Agent 47 in
                                            Hitman Sniper and discover the most compelling sniper experience on mobile.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box garfield-kart">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/garfield-kart.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Garfield Kart</h5>
                                        <p>
                                            What character do you want to play with? Garfield, Odie, Jon, Nermal... you
                                            choose! Take part in no-holds-barred crazy races in either multiplayer mode
                                            or against the computer!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box hendry-danger">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/hendry-danger.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Henry Danger: Crime Warp</h5>
                                        <p>
                                            The Game Shakers &amp; Henry Danger have joined forces to bring you the
                                            Henry Danger Crime Warp game! Collect mad coinage as you slide, jump, dive
                                            and punch giant popcorn monsters, in this non-stop dash to stop them!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box spongebob-frozen-face-off">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/spongebob-frozen-face-off.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Spongebob: Frozen Face-Off</h5>
                                        <p>
                                            Dive in to SpongeBob's first-ever interactive children's book app!
                                            Plankton's got quite the story to tell about SpongeBob and his friends on a
                                            Great Sleigh Race adventure.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box night-racer">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/night-racer.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Night Racer</h5>
                                        <p>
                                            Are you ready for the best race of your life? If yes, Night Racer is exactly
                                            what you need. Amazing realistic graphics will make your blood pump, while
                                            dangerous and life-taking stunts will make your palms sweat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slider-holder" data-duration="140s">
                <div class="scrolling-slider game-tiles">
                    <div class="tile flip-box powerpuff-girls">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/powerpuff-girls.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Powerpuff Girls: Trail Blazer</h5>
                                        <p>
                                            Evil mastermind, Mojo Jojo is plotting to take over the world and it’s up to
                                            the Powerpuff Girls to hunt down their archenemy before he causes chaos!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box missile-command">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/missile-command.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Missile Command</h5>
                                        <p>Missile Command is a 1980 arcade Atari game.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box ben-10">
                        <div class="flip-box-inner">
                            <div class="flip-box-front"><img src="../../assets/images/games-tiles/ben-10.jpg" /></div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Ben 10 World Rescue</h5>
                                        <p>
                                            Travel the world and battle the bad guys as 10 Ben 10 aliens! Travel from
                                            the Yucatan to Tokyo and beyond to save Max and Gwen from Kevin Leven’s evil
                                            clutches.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box tmnt-portal-power">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/tmnt-portal-power.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Teenage Mutant Ninja Turtles: Portal Power</h5>
                                        <p>
                                            Help the Teenage Mutant Ninja Turtles travel through portals and fight evil.
                                            Worlds collide with Classic Krang battles on the rooftops of New York and an
                                            ultimate Kraang Subprime showdown in the Technodrome.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box rayman-jungle">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/rayman-jungle.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Rayman Jungle Run</h5>
                                        <p>
                                            The legendary platforming hero is making the jump to your favorite mobile
                                            device!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box spongebob-game-frenzy">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/spongebob-game-frenzy.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>SpongeBob's Game Frenzy</h5>
                                        <p>
                                            SpongeBob's Game Frenzy! Tap, draw, tilt, shake, drag and scrub your way
                                            through hilarious classic SpongeBob Squarepants moments to save Bikini
                                            Bottom.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box tmnt-half-shell-heroes">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/tmnt-half-shell-heroes.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Teenage Mutant Ninja Turtles: Half-Shell Heroes</h5>
                                        <p>
                                            An official Teenage Mutant Ninja Turtles game made just for you! Join
                                            Leonardo, Michelangelo, Raphael, and Donatello on an action-filled quest
                                            through the sewers and streets of New York City.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box drag-racing">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/drag-racing.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Drag Racing</h5>
                                        <p>
                                            Race in underground street races, earn respect and become the street racing
                                            champion. Win cash, your opponent's cars, and upgrade them to the max.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box lara-croft">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/lara-croft.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Lara Croft: Guardian of Light</h5>
                                        <p>
                                            Join Lara as she ventures deep into the jungles of Central America in search
                                            of the Mirror of Smoke, a powerful artifact from a lost age. Whoever wields
                                            this item has the power to rid the world of light, and hold the future of
                                            humanity in their hands.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="scrolling-slider game-tiles">
                    <div class="tile flip-box powerpuff-girls">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/powerpuff-girls.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Powerpuff Girls: Trail Blazer</h5>
                                        <p>
                                            Evil mastermind, Mojo Jojo is plotting to take over the world and it’s up to
                                            the Powerpuff Girls to hunt down their archenemy before he causes chaos!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box missile-command">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/missile-command.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Missile Command</h5>
                                        <p>Missile Command is a 1980 arcade Atari game.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box ben-10">
                        <div class="flip-box-inner">
                            <div class="flip-box-front"><img src="../../assets/images/games-tiles/ben-10.jpg" /></div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Ben 10 World Rescue</h5>
                                        <p>
                                            Travel the world and battle the bad guys as 10 Ben 10 aliens! Travel from
                                            the Yucatan to Tokyo and beyond to save Max and Gwen from Kevin Leven’s evil
                                            clutches.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box tmnt-portal-power">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/tmnt-portal-power.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Teenage Mutant Ninja Turtles: Portal Power</h5>
                                        <p>
                                            Help the Teenage Mutant Ninja Turtles travel through portals and fight evil.
                                            Worlds collide with Classic Krang battles on the rooftops of New York and an
                                            ultimate Kraang Subprime showdown in the Technodrome.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box rayman-jungle">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/rayman-jungle.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Rayman Jungle Run</h5>
                                        <p>
                                            The legendary platforming hero is making the jump to your favorite mobile
                                            device!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box spongebob-game-frenzy">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/spongebob-game-frenzy.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>SpongeBob's Game Frenzy</h5>
                                        <p>
                                            SpongeBob's Game Frenzy! Tap, draw, tilt, shake, drag and scrub your way
                                            through hilarious classic SpongeBob Squarepants moments to save Bikini
                                            Bottom.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box tmnt-half-shell-heroes">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/tmnt-half-shell-heroes.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Teenage Mutant Ninja Turtles: Half-Shell Heroes</h5>
                                        <p>
                                            An official Teenage Mutant Ninja Turtles game made just for you! Join
                                            Leonardo, Michelangelo, Raphael, and Donatello on an action-filled quest
                                            through the sewers and streets of New York City.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box drag-racing">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/drag-racing.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Drag Racing</h5>
                                        <p>
                                            Race in underground street races, earn respect and become the street racing
                                            champion. Win cash, your opponent's cars, and upgrade them to the max.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box lara-croft">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/lara-croft.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Lara Croft: Guardian of Light</h5>
                                        <p>
                                            Join Lara as she ventures deep into the jungles of Central America in search
                                            of the Mirror of Smoke, a powerful artifact from a lost age. Whoever wields
                                            this item has the power to rid the world of light, and hold the future of
                                            humanity in their hands.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slider-holder" data-direction="ltr" data-duration="180s">
                <div class="scrolling-slider game-tiles">
                    <div class="tile flip-box football-championship-2021">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/football-championship-2021.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Football Championship 2021</h5>
                                        <p>Get back on the field and conquer!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box tennis-pro-2021">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/tennis-pro-2021.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Tennis Pro 2021</h5>
                                        <p>The grandest of tours on the planet, right here and now!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box hey-arnold">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/hey-arnold.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Hey Arnold</h5>
                                        <p>
                                            Arnold and his friends are on an adventure to the jungle, help them find
                                            everything they need in this fun scavenger hunt game. Play Jungle Movie:
                                            Scavenger Hunt and put your skills to test once again.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box homerun-hit">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/homerun-hit.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Homerun Hit</h5>
                                        <p>
                                            Step up to the plate for a great baseball batting experience. Be careful, 10
                                            strikes and you lose.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box nitro-nation">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/nitro-nation.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Nitro Nation Stories</h5>
                                        <p>
                                            The coolest racing game series shifts into high gear with Nitro Nation
                                            Stories!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box deus-ex">
                        <div class="flip-box-inner">
                            <div class="flip-box-front"><img src="../../assets/images/games-tiles/deus-ex.jpg" /></div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Deus Ex: The Fall</h5>
                                        <p>
                                            Deus Ex: The Fall is the start of a new journey in the award winning Deus Ex
                                            game series for Android phones and tablets. Set in 2027 the golden era for
                                            science, technology and human augmentation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box zombies-cant-jump-2">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/zombies-cant-jump-2.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Zombies Can't Jump 2</h5>
                                        <p>
                                            The sequel to Zombies Can’t Jump sees even more zombies trying to eat our
                                            heroes. Keep your defences stacked up high to keep the zombies at bay and
                                            shoot your way through dozens of challenging levels.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box power-rangers-2">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/power-rangers-2.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Power Rangers: Beast Morphers</h5>
                                        <p>
                                            Join the Red, Blue and Yellow Power Rangers in this adventure and face Evox
                                            and his troops. Choose your favorite Power Ranger for each mission and save
                                            the townspeople.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="scrolling-slider game-tiles">
                    <div class="tile flip-box football-championship-2021">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/football-championship-2021.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Football Championship 2021</h5>
                                        <p>Get back on the field and conquer!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box tennis-pro-2021">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/tennis-pro-2021.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Tennis Pro 2021</h5>
                                        <p>The grandest of tours on the planet, right here and now!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box hey-arnold">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/hey-arnold.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Hey Arnold</h5>
                                        <p>
                                            Arnold and his friends are on an adventure to the jungle, help them find
                                            everything they need in this fun scavenger hunt game. Play Jungle Movie:
                                            Scavenger Hunt and put your skills to test once again.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box homerun-hit">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/homerun-hit.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Homerun Hit</h5>
                                        <p>
                                            Step up to the plate for a great baseball batting experience. Be careful, 10
                                            strikes and you lose.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box nitro-nation">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/nitro-nation.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Nitro Nation Stories</h5>
                                        <p>
                                            The coolest racing game series shifts into high gear with Nitro Nation
                                            Stories!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box deus-ex">
                        <div class="flip-box-inner">
                            <div class="flip-box-front"><img src="../../assets/images/games-tiles/deus-ex.jpg" /></div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Deus Ex: The Fall</h5>
                                        <p>
                                            Deus Ex: The Fall is the start of a new journey in the award winning Deus Ex
                                            game series for Android phones and tablets. Set in 2027 the golden era for
                                            science, technology and human augmentation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box zombies-cant-jump-2">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/zombies-cant-jump-2.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Zombies Can't Jump 2</h5>
                                        <p>
                                            The sequel to Zombies Can’t Jump sees even more zombies trying to eat our
                                            heroes. Keep your defences stacked up high to keep the zombies at bay and
                                            shoot your way through dozens of challenging levels.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tile flip-box power-rangers-2">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="../../assets/images/games-tiles/power-rangers-2.jpg" />
                            </div>
                            <div class="flip-box-back">
                                <span class="flip-close">×</span>
                                <div class="flex">
                                    <div class="centered">
                                        <h5>Power Rangers: Beast Morphers</h5>
                                        <p>
                                            Join the Red, Blue and Yellow Power Rangers in this adventure and face Evox
                                            and his troops. Choose your favorite Power Ranger for each mission and save
                                            the townspeople.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        <div class="partners-wrapper static">-->
        <!--            <div class="scrolling-slider partners">-->
        <!--                <img class="logo viacom" src="/build/images/partners/viacom.98f4cb32.png" /><img-->
        <!--                    class="logo nickelodeon"-->
        <!--                    src="/build/images/partners/nickelodeon.b7cee7df.png"-->
        <!--                /><img class="logo dc" src="/build/images/partners/dc.45c86a57.png" /><img-->
        <!--                    class="logo ubisoft"-->
        <!--                    src="/build/images/partners/ubisoft.22f890b9.png"-->
        <!--                /><img class="logo atari" src="/build/images/partners/atari.6eb6eef7.png" /><img-->
        <!--                    class="logo bandai-namco"-->
        <!--                    src="/build/images/partners/bandai-namco.19f2ec98.png"-->
        <!--                /><img class="logo azerion" src="/build/images/partners/azerion.de38b340.png" /><img-->
        <!--                    class="logo capcom"-->
        <!--                    src="/build/images/partners/capcom.23ba5360.png"-->
        <!--                /><img class="logo baltoro" src="/build/images/partners/baltoro.e4ff97ac.png" /><img-->
        <!--                    class="logo cartoon-network"-->
        <!--                    src="/build/images/partners/cartoon-network.fa27b60b.png"-->
        <!--                /><img class="logo square-enix" src="/build/images/partners/square-enix.a2a8a940.png" /><img-->
        <!--                    class="logo plug-in-digital"-->
        <!--                    src="/build/images/partners/plug-in-digital.d22333d8.png"-->
        <!--                /><img class="logo inlogic" src="/build/images/partners/inlogic.4770b5a9.png" /><img-->
        <!--                    class="logo hasbro"-->
        <!--                    src="/build/images/partners/hasbro.55533491.png"-->
        <!--                />-->
        <!--            </div>-->
        <!--        </div>-->
    </section>
</template>
<script>
import $ from 'jquery';
import imagesLoaded from 'vue-images-loaded';
export default {
    name: 'GamesSlider',
    components: {},
    methods: {
        imageLoadedCallback(callback) {
            if (callback) callback();
        },
    },
    directives: {
        imagesLoaded,
    },
    mounted() {
        // dynamic slider animations generator
        let initializedSliders = {};
        let initializeSliderAnimations = function () {
            $('.generated-animation').remove();
            $('.slider-holder').each(function (idx, slider) {
                if (!initializedSliders['slider-loaded-' + idx]) return;

                let animation;
                let $slider = $(slider);
                let width = $slider.find('.scrolling-slider:first').width();
                let duration = $slider.data('duration') || '45s';
                let direction = $slider.data('direction') || 'rtl';
                let selector = 'g-s-a-' + idx;

                $slider.addClass(selector);

                let animationTarget =
                    '.' +
                    selector +
                    ' .scrolling-slider {' +
                    'animation: smoothscroll-' +
                    selector +
                    ' ' +
                    duration +
                    ' linear infinite;' +
                    '-webkit-animation: smoothscroll-' +
                    selector +
                    ' ' +
                    duration +
                    ' linear infinite;' +
                    '}';

                if (direction === 'rtl') {
                    animation =
                        '@keyframes smoothscroll-' +
                        selector +
                        '{' +
                        '0% { transform: translateX(0); }' +
                        '100% { transform: translateX(-' +
                        width +
                        'px); }' +
                        '}' +
                        '@-webkit-keyframes smoothscroll-' +
                        selector +
                        '{' +
                        '0% { -webkit-transform: translateX(0); }' +
                        '100% { -webkit-transform: translateX(-' +
                        width +
                        'px); }' +
                        '}';
                } else {
                    animation =
                        '@keyframes smoothscroll-' +
                        selector +
                        '{' +
                        '0% { transform: translateX(-' +
                        width +
                        'px); }' +
                        '100% { transform: translateX(0); }' +
                        '}' +
                        '@-webkit-keyframes smoothscroll-' +
                        selector +
                        '{' +
                        '0% { -webkit-transform: translateX(-' +
                        width +
                        'px); }' +
                        '100% { -webkit-transform: translateX(0); }' +
                        '}';
                }

                $('head').append('<style class="generated-animation">' + animation + animationTarget + '</style>');
            });
        };

        $('.slider-holder').each((idx) => {
            this.imageLoadedCallback(() => {
                initializedSliders['slider-loaded-' + idx] = true;
                initializeSliderAnimations();
            });
        });

        // trigger sliders re-initialization
        // on horizontal resize only
        let width = $(window).width();
        $(window).on('resize', function () {
            if ($(window).width() != width) {
                initializeSliderAnimations();
                width = $(window).width();
            }
        });

        // flip-box animation handlers
        $('.flip-box').each(function () {
            let imageSrc = $(this).find('img:first').attr('src');
            let className = imageSrc.split('/').slice(-1)[0].split('.')[0];
            $(this).data('target', className);
            $(this).addClass(className);
        });

        $('.flip-box').on('click', function () {
            if (!$(this).hasClass('flipped')) {
                $('.flip-box').removeClass('flipped');
                $('.slider-holder').removeClass('animation-paused');
            }

            $('.flip-box' + '.' + $(this).data('target')).toggleClass('flipped');

            if ($(this).hasClass('flipped')) {
                $(this).parents('.slider-holder').addClass('animation-paused');
            } else {
                $(this).parents('.slider-holder').removeClass('animation-paused');
            }
        });

        // pause animations while not in viewport
        $.fn.isInViewport = function () {
            let elementTop = $(this).offset().top;
            let elementBottom = elementTop + $(this).outerHeight();

            let viewportTop = $(window).scrollTop();
            let viewportBottom = viewportTop + $(window).height();

            return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        let $gameRowsWrapper = $('.game-rows-wrapper');
        let handleAnimationState = function () {
            if ($gameRowsWrapper.isInViewport()) {
                $gameRowsWrapper.removeClass('animation-paused');
            } else {
                $gameRowsWrapper.addClass('animation-paused');
            }
        };

        if ($gameRowsWrapper.length) {
            handleAnimationState();
            $(window).on('resize scroll', function () {
                handleAnimationState();
            });
        }
    },
};
</script>
