<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="privacy-policy-content">
                        <!--                        <img src="../../assets/images/privacy-policy.jpg" alt="image" />-->
                        <p><i>Last updated November 20, 2023.</i></p>
                        <h3>1. Introduction</h3>
                        <p>
                            This is <strong>GM Europe Limited</strong> (hereafter also “<strong>Service Provider</strong
                            >“, “<strong>us</strong>“, “<strong>we</strong>“, “<strong>Digital Ditribution</strong>",
                            “<strong>digitaldistribution.io</strong>", "<strong>ddg.link</strong>" or
                            "<strong>go.ddg.link</strong>"). Data Privacy Notice for the processing of personal data of
                            Users of our Services.
                        </p>
                        <p>
                            Data privacy is of utmost importance to us. This notice aims to inform our Users on the
                            instances in which GM Europe collects and uses personal information and on the Users’ rights
                            they enjoy in this regard (under the EU General Data Protection Regulation).
                        </p>
                        <p>
                            This Privacy Notice may be changed over time. You are advised to regularly review the
                            Privacy Notice for possible changes. This Privacy Notice was last changed on November 20,
                            2023.
                        </p>
                        <h3>2. When does this privacy Notice apply?</h3>
                        <p>
                            This Privacy Notice is applicable to the processing by GM Europe of all personal data of its
                            Users while using the GM Europe tracking and advertising services (before and hereafter also
                            “<strong>Services</strong>”).
                        </p>
                        <h3>3. Who is responsible for your personal data?</h3>
                        <p>The controller for the processing of your personal data is</p>
                        <p>
                            <strong>GM Europe Limited <br /> </strong
                            ><strong
                                >Dragonara Business Center 5th Floor, Dragonara Road, St Julian’s, STJ 3141,
                                Malta</strong
                            > <br/> <strong
                                >Company number C 87607 <br />
                                E-Mail: contact@digitaldistribution.io <br />
                                Website: http://digitaldistribution.io</strong
                            >
                            <strong></strong>
                        </p>
                        <h3>4. For which purposes do we process your personal data?</h3>
                        <h5 class="ps-2">4.1 For purposes of providing the Services</h5>
                        <ul class="list-unstyled ps-4">
                            <li>
                                <p><strong>(a)</strong> <strong>What does this purpose entail?</strong></p>
                                <p>
                                    If you wish to make use of one of our Services, we process personal data that you
                                    make available to us in order to provide you with these Services.
                                </p>
                                <p>
                                    <strong>(b)</strong>
                                    <strong> On what legal basis do we process personal data for this purpose? </strong>
                                </p>
                                <p>
                                    The processing is necessary for the display of the advertisement and offer that you
                                    requested to see. The provision of the data is a requirement to receive our
                                    Services. If you don’t provide the necessary personal data, you cannot use our
                                    Services.
                                </p>
                                <p>
                                    <strong>(c)</strong>
                                    <strong> Which personal data do we process for this purpose? </strong>
                                </p>
                                <p>
                                    For this purpose, we process your IP address and the handset type of the device you
                                    are using.
                                </p>
                                <p>
                                    <strong>(d)</strong>
                                    <strong> How long do we retain your personal data for this purpose? </strong>
                                </p>
                                <p>
                                    We retain your personal data for as long as it is necessary to provide you with our
                                    Services or to process users’ complaints or requests relating to the Services, and
                                    as long as it is required by law. In addition, we retain the personal data for as
                                    long as it is necessary for any establishment, exercise or defence of legal claims.
                                </p>
                            </li>
                        </ul>

                        <h5 class="ps-2">4.2 To answer your questions</h5>
                        <ul class="list-unstyled ps-4">
                            <li>
                                <p><strong>(a)</strong> <strong>What does this purpose entail?</strong></p>
                                <p>
                                    If you get in touch with us via our support email addresses, we will use your
                                    personal data in order to reply and answer your questions.
                                </p>
                                <p>
                                    <u>Support email address:</u>
                                    <strong> contact@digitaldistribution.io </strong>
                                    <u></u>
                                </p>
                                <p>
                                    <strong>(b)</strong>
                                    <strong> On what legal basis do we process personal data for this purpose? </strong>
                                </p>
                                <p>
                                    The processing is necessary for the purposes of the legitimate interests pursued by
                                    us and you. Your interest is to get the information you request and our interest is
                                    to answer the requests of our Users or potential Users.
                                </p>
                                <p>
                                    <strong>(c)</strong>
                                    <strong> Which personal data do we process for this purpose? </strong>
                                </p>
                                <p>
                                    For this purpose, we process your name, contact details, your correspondence with us
                                    with regard to your question and all other personal data which are necessary to
                                    answer your question.
                                </p>
                                <p>
                                    <strong>(d)</strong>
                                    <strong> How long do we retain your personal data for this purpose? </strong>
                                </p>
                                <p>
                                    We retain your personal data for as long as it is necessary to answer your request
                                    and as is required by law. In addition, we retain the personal data for as long as
                                    it is required for any establishment, exercise or defence of legal claims.
                                </p>
                            </li>
                        </ul>

                        <h3>5. Who has access to your personal data?</h3>
                        <h4 class="ps-2">5.1 Access to your personal data by third parties</h4>
                        <ul class="list-unstyled ps-4">
                            <li>
                                <p>
                                    The following third parties have access to your personal data where relevant for the
                                    provisioning of their products or services to GM Europe:
                                </p>
                                <ul>
                                    <li>Customer Service companies: IPG</li>
                                </ul>
                                <p>
                                    · Amazon Web Services, Inc., 410 Terry Avenue North Seattle WA 98109, United States
                                    (all User data is stored in Frankfurt, Germany)
                                </p>
                                <p>
                                    If your personal data are transferred to a recipient in a country that does not
                                    provide an adequate level of protection of personal data, GM Europe Limited will
                                    take measures to ensure that your personal data are adequately protected, such as
                                    entering into EU Standard Contractual Clauses with these third parties.
                                </p>
                                <p>
                                    In other cases, your personal data will not be supplied to third parties, except
                                    when required by law.
                                </p>
                            </li>
                        </ul>

                        <h4 class="ps-2">5.2 The use of your personal data by data processors</h4>
                        <p>
                            When a third party processes your personal data solely following GM Europe’s instructions,
                            it acts as a “data processor”. In line with the requirements of the EU General Data
                            Protection Regulation, we enter into an agreement with such data processors concerning the
                            processing of personal data. In this agreement we include, at a minimum, the obligations and
                            safeguards to ensure that your personal data are adequately protected.
                        </p>
                        <h3>6. How are your personal data secured?</h3>
                        <p>
                            GM Europe has taken adequate safeguards to ensure the confidentiality and security of your
                            personal data. In particular, GM Europe has implemented appropriate technical, physical and
                            organisational measures to protect personal data against accidental or unlawful destruction
                            or accidental loss, damage, alteration, unauthorised disclosure or access, and against all
                            other forms of unlawful processing (including, but not limited to unnecessary collection) or
                            further processing.
                        </p>
                        <h3 class="text-uppercase">
                            7. Questions or requests for access, MODIFICATION, Rectification, erasure, restriction, data
                            portability or objection?
                        </h3>
                        <p>
                            The EU General Data Protection Regulation (GDPR) grants you a number of rights. You can request
                            access (Art 15 GDPR), rectification (Art 16 GDPR), erasure (Art 17 GDPR), restriction (Art
                            18 GDPR), data portability (Art 20 GDPR) or objection (Art 21 GDPR) of the data that GM
                            Europe processes about you at any time by sending a request to
                            contact@digitaldistribution.io
                        </p>
                        <p>
                            If you feel that we do not comply with the applicable privacy rules you also have the right
                            to lodge a complaint with a competent data protection authority.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy',
};
</script>
